// import DefaultNavbar from 'components/DefaultNavbar';
import Header from 'components/landing/Header';
// import WorkingSection from 'components/landing/WorkingSection';
// import TeamSection from 'components/landing/TeamSection';
// import ContactSection from 'components/landing/ContactSection';
// import SimpleFooter from "../components/SimpleFooter";

export default function Landing() {
    return (
        <>
            {/*<div className="absolute w-full z-20">*/}
            {/*    <DefaultNavbar />*/}
            {/*</div>*/}
            <main>
                <Header />
                {/*<WorkingSection />*/}
                {/*<TeamSection />*/}
                {/*<ContactSection />*/}
            </main>
            {/*<SimpleFooter />*/}
        </>
    );
}
